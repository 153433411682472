<template>
  <div>
    <v-data-table :loading="isLoading('get:equipamento')" :items="tableData" :search="search" :headers="headers">
      <template v-slot:top>
        <v-row class="mt-4" dense>
          <v-col cols="5">
            <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable />
          </v-col>
          <v-col class="text-end">
            <v-btn color="success" class="elevation-0" @click="dialog.create = true">
              <v-icon left>
                mdi-plus
              </v-icon>
              Novo
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn icon @click="onSelectRow(item, 'update')">
          <v-icon color="green">
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
          <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
        </v-chip>
      </template>
      <template v-slot:[`item.custo`]="{ item }">
        {{ item.custo | formatReal }}
      </template>
      <template v-slot:[`item.valor_cobranca`]="{ item }">
        <div v-if="item.valor_cobranca">
          {{ item.valor_cobranca | formatReal}}
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog.create" v-if="dialog.create" max-width="1000">
      <v-card>
        <v-card-title class="blod_color">
          Novo equipamento:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <BaseFormEquipamento :currentItem="newItem" :loading="isLoading('post:equipamento')" :onSubmit="createEquipamento" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.update" v-if="dialog.update" max-width="1000">
      <v-card>
        <v-card-title class="blod_color">
          Editar equipamento:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <BaseFormEquipamento :currentItem="selectedRow" :loading="isLoading('put:equipamento')" :onSubmit="updateEquipamento" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../../../../../http';
import UtilsFunc from '../../../../../service/utilsFunc';
import BaseFormEquipamento from './BaseFormEquipamento.vue';
import './../../../../../assets/css/styles.css';

export default {
  name: "equipamento",
  data() {
    return UtilsFunc.withCRUDUtils({
      newItem: { ativo:1 },
      headers: [
        { text: "Editar", value: "edit", width: 50 },
        { text: "Id", value: "id", width: 90 },
        { text: "Nome do equipamento", value: "nome" },
        { text: "Custo estimado", value: "custo" },
        { text: "Valor do Equipamento para Reposição", value: "valor_cobranca" },
        { text: "Status", value: "ativo" },
      ],
      defaultSort: {
        name: 'ativo',
        isDesc: true
      }
    });
  },
  methods: {
    async getEquipamento() {
      const LOADING_NAME = "get:equipamento";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/equipamentos/nomeequipamentos/");
        data.sort((a, b) => {
          // Ordena por ativo (true vem antes de false)
          if (a.ativo !== b.ativo) {
            return a.ativo ? -1 : 1;
          }
          // Se ambos são ativos ou inativos, ordena por nome
          return a.nome.localeCompare(b.nome);
        });
        this.tableData = data;
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async createEquipamento(fields) {
      const LOADING_NAME = "post:equipamento";
      this.setLoading(LOADING_NAME);
      try {
        await api.post("/equipamentos/nomeequipamentos/", fields);
        await this.getEquipamento()
        this.dialog.create = false
        this.$toast.success('Equiopamento criado com sucesso!')
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async updateEquipamento(fields) {
      const LOADING_NAME = "put:equipamento";
      this.setLoading(LOADING_NAME);
      try {
        await api.put(`/equipamentos/nomeequipamento/${fields.id}/`, fields);
        await this.getEquipamento()
        this.dialog.update = false
        this.$toast.success('Equipamento atualizado com sucesso!')
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
  },
  mounted() {
    this.getEquipamento();
  },
  components: { BaseFormEquipamento }
}
</script>
